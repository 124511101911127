import React from 'react';

import './style.scss';
import SEO from './seo';
import Header from './header';


const Layout = ({ children }) => (
	<div>
		<SEO />
		<Header />
	</div>
);

export default Layout;
